import React, { Fragment } from "react";
import { ResetCSS } from "../assets/css/style";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";

import { ConfirmationSection } from "@containers/common";
import { SEO } from "@components/index";
import { GlobalStyle, ContentWrapper } from "../assets/css/saas.style";
import { DrawerProvider } from "../contexts/DrawerContext";
import { Footer, Navbar } from "@containers/common";
import { theme } from "../common/theme";

const ConfirmationPage = () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <SEO title="Job apply confirmation" />
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar notMainPage />
          </DrawerProvider>
        </Sticky>
        <ConfirmationSection />
        <Footer />
      </ContentWrapper>
    </Fragment>
  </ThemeProvider>
);

export default ConfirmationPage;
